@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap');

@tailwind base;
@layer base {
	img {
		@apply inline-block;
	}
}
@tailwind components;
@tailwind utilities;
* {
	scrollbar-width: thin;
	scrollbar-color: #fe9f00 transparent;
}

@layer base {
	h1 {
		@apply text-gray-800;
		@apply font-[600];
		@apply font-inter;
		@apply text-[34px];
		@apply leading-[40px];
		@apply tracking-[-1px];
	}
	h2 {
		@apply text-gray-800;
		@apply font-[600];
		@apply font-inter;
		@apply text-[24px];
		@apply leading-[30px];
		@apply tracking-[-1px];
	}
	h3 {
		@apply text-gray-800;
		@apply font-bold;
		@apply font-inter;
		@apply text-[18px];
		@apply leading-[26px];
		@apply tracking-[-1px];
	}
	p {
		@apply text-gray-800;
		@apply font-normal;
		@apply font-inter;
		@apply text-[16px];
		@apply leading-[24px];
		@apply tracking-[0px];
	}
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #fe9f00;
	border-radius: 20px;
	border: 3px solid transparent;
}

.table-row td {
	-webkit-transition: border-bottom-right-radius 0.3s
		cubic-bezier(0.11, 0.24, 0, 0.51);
	transition: border-bottom-right-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
	-webkit-transition: border-bottom-left-radius 0.3s
		cubic-bezier(0.11, 0.24, 0, 0.51);
	transition: border-bottom-right-radius 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
	-webkit-transition: border-color 0.1s cubic-bezier(0.11, 0.24, 0, 0.51);
	transition: border-color 0.1s cubic-bezier(0.11, 0.24, 0, 0.51);
	transition: background-color 0.1s !important;
	-webkit-transition: background-color 0.1s !important;
	transition: color 0.3s linear;
}

.side-color {
	transition: width 0.1s linear;
}

.second-row td {
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	-webkit-transition: border-color 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
	transition: border-color 0.3s cubic-bezier(0.11, 0.24, 0, 0.51);
}

:root {
	--toastify-color-error: theme('colors.primary.500');
	--toastify-color-success: theme('colors.primary.500');
}

progress.fileupload[value] {
	--color: linear-gradient(#fff8, #fff0),
		repeating-linear-gradient(135deg, #0003 0 10px, #0000 0 20px), #fe9f00; /* the progress color */
	--background: lightgrey; /* the background color */

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 10em;
	background: var(--background);
}
progress.fileupload[value]::-webkit-progress-bar {
	border-radius: 10em;
	background: var(--background);
}
progress.fileupload[value]::-webkit-progress-value {
	border-radius: 10em;
	background: var(--color);
}
progress.fileupload[value]::-moz-progress-bar {
	border-radius: 10em;
	background: var(--color);
}

progress.percentbar[value] {
	--color: #fe9f00; /* the progress color */
	--background: lightgrey; /* the background color */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	background: var(--background);
	height: 6px;
}
progress.percentbar[value]::-webkit-progress-bar {
	background: var(--background);
}
progress.percentbar[value]::-webkit-progress-value {
	background: var(--color);
}
progress.percentbar[value]::-moz-progress-bar {
	background: var(--color);
}
